<template>
  <div></div>
</template>
<script setup lang="ts">
import { useRoute } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { showAlert } from '@/composables/useDialog';
import {
  MENU_ACCESS_DENY_ERROR_CODE,
  PRODUCT_ACCESS_DENY_ERROR_CODE
} from '@/constants/error.const';
import { GROUP_REGISTER_PAGE_URL, HOME_PAGE_URL } from '@/constants/url.const';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';
import { definePageMeta } from '#imports';

definePageMeta({
  middleware: [
    'check-login-middleware',
    'check-member-register-middleware',
    'check-user-age-middleware',
    'check-agree-term-middleware',
    'redirect-middleware'
  ]
});

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);
const route = useRoute();
const { t } = useI18n();

const redirectToHome = async (group?: string) => {
  const groupId = group ?? (await userStore.findReadableGroupHome());
  if (groupId === '') {
    return await redirectTo(GROUP_REGISTER_PAGE_URL);
  }
  userStore.setGroupData(groupId);
  return await redirectTo(HOME_PAGE_URL, { groupId });
};

const handleMenuAccessDeny = async () => {
  await showAlert({ content: t('studio.common.popup_case_g_not_view') });
  await redirectToHome();
};

const handleProductAccessDeny = async () => {
  await showAlert({ content: t('studio.common.popup_case_g_not_view') });
  await redirectToHome(selectedGroupId.value);
};

const init = async () => {
  const { code } = route.query;
  if (code === MENU_ACCESS_DENY_ERROR_CODE) {
    return await handleMenuAccessDeny();
  }
  if (code === PRODUCT_ACCESS_DENY_ERROR_CODE) {
    return await handleProductAccessDeny();
  }
};

onMounted(() => {
  init();
});
</script>
